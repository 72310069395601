console.log("yooooooo");
// WARNING: For POST requests, body is set to null by browsers.
var data = JSON.stringify({
  "store_name": "nuvaano.myshopify.com"
});

var xhr = new XMLHttpRequest();

xhr.addEventListener("readystatechange", function() {
  if(this.readyState === 4) {
    var jsonResponse = JSON.parse(this.responseText);
    var status = jsonResponse.status;
      console.log(status);
      console.log(jsonResponse);
if(status) {
    if(jsonResponse.image){
      var image = "https://dialog.modish.no/" + jsonResponse.image;
    }else{
      var image = "https://cdn.shopify.com/s/files/1/0079/5636/5423/files/PHOTO-2024-02-05-13-57-21.jpg?v=1710677624"
    }
     
    var html = '<div id="popup" class="popup">';
    html += ' <div class="popup-content" id="popup-content-wrapper" style="background: '+ jsonResponse.background_color  +';">';
    html += '<div class="popup-image-wrapper"><img id="popup-image" width="100%" height="100%" src="'+ image +'"></div>';
    html += ' <div class="popup-content-text-wrapper">';
    html += ' <span class="popup-close" onclick="closePopup()">&times;</span>';
    html += '<div class="newsletter-popup__content custom-1">';
    html += '<h3 id="pop_header_text" style= "color: '+ jsonResponse.header_text_color +'; font-size: '+ jsonResponse.header_text_size + "px" +';">'+ jsonResponse.header_text +'</h3>';
    html += ' <div id="pop_description">'+ jsonResponse.description +'</div>';
    html += '<form method="GET" action="/a/loyalty/bli_medlem">';
    html += '<div class="popup-input-container">';
    html += '<input class="input-field" type="email" placeholder="'+ jsonResponse.email_placeholder +'" name="email" id="popup-input-email" style= "background-color: '+ jsonResponse.email_background_color +'; border-color: '+ jsonResponse.email_border_color +';">';
    if(jsonResponse.button_text){
      html += '<button type="button" id="popupButton"  target="_blank" class="button-link-text" style="font-size: 12px; padding: 10px 25px; width: 50%; background-color: '+ jsonResponse.button_background_color +'; color: '+ jsonResponse.button_text_color +';">'+ jsonResponse.button_text +'</button> ';
    }else{
      html += '<button type="button" id="popupButton"  target="_blank" class="button-link-text" style="background-color: '+ jsonResponse.button_background_color +'; color: '+ jsonResponse.button_text_color +';"><img src="https://cdn.shopify.com/s/files/1/0567/1380/5987/files/up-arrow-svgrepo-com.svg?v=1708366385"  alt=""  width="20" height="20"></button> ';
    };
    html += '<input type="hidden" name="oic" value="shopify_popup">';
    html += ' </div>';
    html += '</form>';
    html += '</div>';
    html += '</div>';
    html += '</div>';
    html += '</div>';
    
    document.getElementById('custom_popup').innerHTML = html;
  console.log(document.getElementById('custom_popup'));
   }

    

  }
});

xhr.open("POST", "https://dialog.modish.no/api/popup-attributes");
xhr.setRequestHeader("Content-Type", "application/json");

xhr.send(data);


// popup js code

  document.addEventListener("DOMContentLoaded", function () {
  setTimeout(() => {
      popup = document.getElementById("popup");
    function showPopup() {
          if (document.cookie.indexOf("popupShown=true") === -1) {
            popup.style.display = "flex";
             document.cookie = "popupShown=true";
           }
    }
      
      closeButton = document.querySelector(".popup-close");
  
      // Function to show the popup
    
      
  
      // Function to close the popup
      function closePopup() {
          popup.style.display = "none";
      }
  
      // Show the popup when the page loads
      showPopup();
  
      // Close the popup when the close button is clicked
    
      closeButton.addEventListener("click", closePopup);

    $('#popupButton').click(function (e) { 
  e.preventDefault();
  var emailValue = $('input[id="popup-input-email"]').val();
  window.location.href = "https://nuvaa.no/a/loyalty?email=" + encodeURIComponent(emailValue);
});
   }, 20000);
    
    
  });







